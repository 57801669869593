import React, { useState } from "react"
import {
  graphql,
  // Link,
  // navigate
} from "gatsby"
import ReactPlayer from 'react-player/youtube'
import { Layout } from "../components"
import {
  MissionControl,
  Modal,
  NextLaunch,
} from "../components"
import flag from '../assets/images/icons/flag.svg'
import code from '../assets/images/icons/code.svg'
import telescope from '../assets/images/icons/telescope.svg'

const SPACE = ({ data }) => {
  const missions = data.allWpMission.nodes
  const [displayVideoModal, setDisplayVideoModal] = useState(false)

  return (
    <Layout>
    <header className="page-header">
       <span className="color-tint"></span>
       <div className="text-container">
         <img
           alt="SPACE - Our Launch  Destination"
           src="https://space-station.s3.amazonaws.com/sites/566b5aa0d7bcec0003000001/theme/images/space-logo.svg?1593194907"
           className="home-logo"
         />
       </div>
     </header>
      <section className="text-block-container">
        <h1>We Explore the Unknown</h1>
        <p className="subheader">
        We as a company, and as individuals, are inspired by the unknown. We do our best work in the unknown. SPACE is our internal innovation program where we experiment with new technologies, explore our passions, launch products, and have fun with our own ideas.
        </p>
        <button className="modal-trigger button-white-outline video-button"
          onClick={() => setDisplayVideoModal(true)}
        >
          <span>Watch Video</span>
        </button>
        <Modal isOpen={displayVideoModal} onClose={() => setDisplayVideoModal(false)}>
          <ReactPlayer
            className="react-player"
            height="100%"
            url="https://youtu.be/2c_fcfUx3O8"
            width="100%"
          />
        </Modal>
      </section>
      <section className="grid-block-container">
        <ul>
          <li>
            <img src={flag} alt="" />
            <h2>Demo Days</h2>
            <p>Demo Days provide a safe space to launch new ideas, get feedback, and show off our creativity each quarter. These days accelerate our passion to keep learning and keep growing.</p>
          </li>
          <li>
            <img src={code} alt="" />
            <h2>OPEX</h2>
            <p>At our core, we believe in building reusable code and open source pieces to become more efficient. SPACE allows us to focus, iterate, and improve in our Operational Excellence (OPEX).</p>
          </li>
          <li>
            <img src={telescope} alt="" />
            <h2>STIP</h2>
            <p>Straight from NASA, we’ve adopted our own Strategic Technology Investment Plan. SPACE helps us explore new, emerging technologies and evaluate how they might fit into our technology stack.</p>
          </li>
        </ul>
      </section>
      <MissionControl missions={missions} />
      <NextLaunch title="Demo Day In" />
    </Layout>
  )
}

export default SPACE

export const query = graphql`
  {
    allWpMission(filter: {mission_fields: {featured: {eq: "Y"}, launchDate: {ne: null}}}) {
      nodes {
        slug
        title
        mission_fields {
          missionPatch {
            altText
            mediaItemUrl
          }
          missionType
          teaser
          launchDate
        }
      }
    }
  }
`
